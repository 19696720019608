<template>
  <div class="skimovieProblems">
    <template v-if="loading">
      <LoadingPlaceholder class="my-4" />
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12 col-lg-6 mb-4">
          <Portlet
            title="Bad Tracking Spots"
            icon="bug"
          >
            <SkimovieProblemsIssueSpots :issues="badTrackingSpots" />
          </Portlet>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <Portlet
            title="Hardware Issue Spots"
            icon="bug"
          >
            <SkimovieProblemsIssueSpots :issues="hardwareIssues" />
          </Portlet>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SkimovieProblems',
  metaInfo () {
    return {
      title: this.title
    }
  },
  components: {
    SkimovieProblemsIssueSpots: () => import('@/components/Problem/SkimovieProblemsIssueSpots.vue')
  },
  data () {
    return {
      loading: true,
      hardwareIssues: null,
      badTrackingSpots: null,
      activeSpots: null
    }
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      this.axios.all([
        this.axios.get('/Report/GetBadSpots'),
        this.axios.get('/Report/GetActiveSpots'),
        this.axios.get('/Report/GetHardwareIssueSpots')
      ]).then(
        this.axios.spread((badSpotsResponse, activeSpotResponse, hardwareIssueResponse) => {
          if (badSpotsResponse.data) {
            this.badTrackingSpots = badSpotsResponse.data;
          }
          if (activeSpotResponse.data) {
            this.activeSpots = activeSpotResponse.data;
          }
          if (hardwareIssueResponse.data) {
            this.hardwareIssues = hardwareIssueResponse.data;
          }
      }))
      .catch(() => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
